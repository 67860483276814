export default function (items) {
  return {
    content: `${wrapItems(items)}`,
    classes: 'tooltip_managers',
    placement: 'bottom-start',
    trigger: 'click',
  }
}

function wrapItems(items) {
  return `
    <div class="tooltip__items">
      ${items.map(wrapItem).join('')}
    </div> 
  `
}

function wrapItem(item) {
  return `
    <div class="tooltip__item">
      <img src="${item.avatar_url}">
      ${item.name}
    </div>
  `
}
