<template>
  <div class="table">
    <div class="table__header">
      <div class="flex items-center">
        <h1 class="title-h1">Проекты</h1>

        <div class="ml-8">
          <BmTabs :tabs="tabs" />
        </div>

        <div class="ml-auto">
          <BmSelect
            :option="filterUser"
            :options="managers"
            label="Менеджер"
            @update:option="setFilterUser"
          />
        </div>

        <div class="ml-8">
          <BmSelect
            :option="filterDays"
            :options="getWeeks()"
            :reset="false"
            @update:option="setFilterDays"
          />
        </div>
      </div>
      <div class="table__thead">
        <div class="table__tr">
          <div class="table__th pr-8">
            <div class="table__th-content">
              <div style="width: 40px"></div>
              <div
                :class="
                  sortDirection ? 'table__th-sortable_' + sortDirection : ''
                "
                class="table__th-label table__th-sortable"
                @click="setSortDirection"
              >
                Название
              </div>
              <div class="table__th-week ml-auto">
                <div v-for="date in datesRange" style="width: 70px">
                  <div class="table__th-label text-right text-grey-light">
                    {{ date.format('dd') }}
                  </div>
                  <div class="table__th-value text-right">
                    {{ date.format('DD.MM') }}
                  </div>
                </div>
                <div class="active" style="width: 110px">
                  <div class="table__th-label text-right text-grey-light">
                    Тек неделя
                  </div>
                  <div class="table__th-value text-right">
                    {{ filterDays.name }}
                  </div>
                </div>
                <div @click="incrementWeek(-1)">
                  <BmIconChevronLeft class="table__th-prev" />
                </div>
                <div @click="incrementWeek(1)">
                  <BmIconChevronRight class="table__th-next" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <skeleton-table v-if="loading" :rows-count="currentWorkload.length" />
    <template v-else>
      <OverlayScrollbars>
        <div class="table__tbody">
          <div
            v-for="(project, i) in currentWorkload"
            :key="'wp' + project.id"
            class="table__tr table__tr_body"
            @click="expandRow(i)"
          >
            <div
              :class="{table__td_active: project.expand}"
              class="table__td pr-8"
            >
              <div
                :class="{'table__td-content_active': project.expand}"
                class="table__td-content"
              >
                <div style="width: 40px">
                  <BmIconChevronRight
                    :class="{'table__expand-icon_expanded': project.expand}"
                    class="table__expand-icon"
                    color="black"
                  />
                </div>
                <div class="table__td-label">{{ project.name }}</div>
                <div class="flex items-center ml-auto no-wrap">
                  <div v-for="date in datesRange">
                    <div v-for="projectDay in project.days" style="width: 70px">
                      <template
                        v-if="
                          date.format('YYYY-MM-DD') === projectDay.scheduledDate
                        "
                      >
                        <div
                          :key="
                            'wp' + project.id + 'd' + date.format('YYYY-MM-DD')
                          "
                          :class="{
                            'text-grey-light': projectDay.duration / 3600 === 0,
                          }"
                          class="text-right pl-4"
                          style="width: 70px"
                        >
                          {{ projectDay.duration / 3600 }} ч
                        </div>
                      </template>
                    </div>
                  </div>
                  <div class="text-right" style="width: 110px">
                    {{ project.totalDuration / 3600 }} ч
                  </div>
                </div>
              </div>
              <div
                :class="{table__expansion_active: project.expand}"
                class="table__expansion"
              >
                <div
                  v-for="user in project.users"
                  :key="'wpu' + user.id"
                  class="table__td-content no-border"
                >
                  <div style="width: 40px"></div>
                  <div class="flex items-center">
                    <BmBadge
                      v-tooltip="user.positions"
                      :label="user.positions"
                      class="mr-4"
                    />

                    <BmAvatar
                      v-tooltip="user.name"
                      :src="user.avatar"
                      class="mr-4"
                      size="lg"
                      @click="setUser(user)"
                    />

                    <div>
                      <div class="text-black">{{ user.name }}</div>
                      <div class="text-grey-dark">{{ user.units }}</div>
                    </div>
                  </div>

                  <div class="flex items-center ml-auto">
                    <div v-for="date in datesRange">
                      <div
                        v-for="scheduledTime in user.scheduledTimes"
                        style="width: 70px"
                      >
                        <template
                          v-if="
                            date.format('YYYY-MM-DD') ===
                            scheduledTime.scheduledDate
                          "
                        >
                          <div
                            :key="
                              'wpu' + user.id + 'd' + date.format('YYYY-MM-DD')
                            "
                            :class="{
                              'text-grey-light':
                                scheduledTime.duration / 3600 === 0,
                              'text-red': scheduledTime.duration / 3600 > 8,
                            }"
                            class="text-right"
                          >
                            {{ scheduledTime.duration / 3600 }} ч
                          </div>
                        </template>
                      </div>
                    </div>
                    <div class="text-right" style="width: 110px">
                      {{ lodash.sumBy(user.scheduledTimes, 'duration') / 3600 }}
                      ч
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </OverlayScrollbars>
    </template>
  </div>
</template>

<script>
import api from '@/api'
import {mapActions, mapState, mapMutations} from 'vuex'
import {daysRange, weekLabel, getWeeks} from '@src/utils/helpers'
import OverlayScrollbars from '@src/components/overlay-scrollbars/OverlayScrollbars'
import SkeletonTable from './WorkloadSkeletonTable'

export default {
  name: 'WorkloadTable',
  components: {
    OverlayScrollbars,
    SkeletonTable,
  },
  props: {
    tabs: [],
  },
  data() {
    return {
      currentWorkload: [],
      sortDirection: null,
      loading: false,
      datesRange: [],
      managers: [],
    }
  },
  methods: {
    ...mapMutations({
      setFilterDays: 'projects/setFilterDays',
      setFilterUser: 'projects/setFilterUser',
    }),
    ...mapActions({
      fetchWorkload: 'projects/fetchWorkload',
    }),
    daysRange,
    weekLabel,
    getWeeks,
    incrementWeek(increment) {
      const date = this.$moment(this.filterDays.id, 'YYYY-MM-DD').add(
        increment,
        'w'
      )

      this.setFilterDays({
        id: date,
        name: this.weekLabel(date),
      })
    },
    expandRow(index) {
      const row = {
        ...this.currentWorkload[index],
        expand: !this.currentWorkload[index].expand,
      }

      this.$set(this.currentWorkload, index, row)
    },
    setSortDirection() {
      this.sortDirection = !this.sortDirection
        ? 'asc'
        : this.sortDirection === 'asc'
        ? 'desc'
        : null
    },
  },
  watch: {
    filterDays() {
      this.datesRange = this.daysRange(this.filterDays.id)

      this.fetchWorkload({
        startDate: this.datesRange[0].format('YYYY-MM-DD'),
        endDate: this.datesRange.slice(-1)[0].format('YYYY-MM-DD'),
      })
    },
    workload() {
      this.currentWorkload = [...this.workload]
    },
    filterUser(value) {
      if (!value) {
        this.currentWorkload = [...this.workload]
        return
      }

      this.currentWorkload = this.workload.filter((project) =>
        project.managers.find((user) => user.id === value.id)
      )
    },
    sortDirection() {
      if (this.sortDirection === 'asc') {
        this.currentWorkload.sort((a, b) => a.name.localeCompare(b.name))
      } else if (this.sortDirection === 'desc') {
        this.currentWorkload.sort(() => -1)
      } else {
        this.currentWorkload = [...this.workload]
      }
    },
    users() {
      this.managers = this.users.filter(
        (user) =>
          user.position &&
          process.env.VUE_APP_MANAGER_POSITION_IDS.split(',').includes(
            user.position.id.toString()
          )
      )
    },
  },
  computed: {
    ...mapState({
      workload: (state) => state.projects.workload,
      filterDays: (state) => state.projects.filterDays,
      filterUser: (state) => state.projects.filterUser,
      currentTabKey: (state) => state.projects.currentTabKey,
    }),
  },
  async created() {
    const date =
      this.filterDays?.id ?? this.$moment().weekday(0).format('YYYY-MM-DD')

    this.setFilterDays({
      id: date,
      name: this.weekLabel(date),
    })

    if (this.filterUser) {
      this.setFilterUser(this.filterUser)
    }

    this.managers = await api.users.managers()
  },
}
</script>
