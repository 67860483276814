export default {
  name: {
    name: 'name',
    sortable: true,
    label: 'Название',
    style: 'width:40%;text-align:left',
  },
  client: {
    name: 'client.name',
    sortable: true,
    label: 'Клиент',
    style: 'width:20%;text-align:left',
  },
  managers: {
    name: 'managers',
    label: 'Менеджер',
    style: 'width:20%;text-align:left',
  },
  budget: {
    name: 'budget',
    sortable: true,
    sortLeft: true,
    sort: (a, b) => a - b,
    label: 'Бюджет',
    style: 'width:20%;text-align:right',
  },
}
