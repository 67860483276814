<template>
  <div class="table">
    <div class="table__header">
      <div class="flex items-center">
        <h1 class="title-h1">Проекты</h1>
        <div class="ml-8">
          <BmTabs :tabs="tabs" />
        </div>
        <div class="ml-auto">
          <BmSelect
            :option="filterUser"
            :options="managers"
            label="Менеджер"
            @update:option="setFilterUser"
          />
        </div>
      </div>
      <div class="table__thead">
        <div class="table__tr">
          <div class="table__th">
            <div class="table__th-content">
              <div
                v-for="column in columns"
                :key="'c' + column.name"
                :style="column.style"
              >
                <div
                  :class="thLabelClasses(column)"
                  class="table__th-label"
                  @click="setSort(column.name)"
                >
                  {{ column.label }}
                </div>
              </div>
              <div style="width: 30px"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <skeleton-table v-if="false" :count="filteredProjects.length" />
    <template v-else>
      <div class="table__tbody">
        <div
          v-for="project in filteredProjects"
          :key="'p' + project.id"
          class="table__tr table__tr_body"
        >
          <div class="table__td">
            <div
              class="table__td-content"
              @click="toProjectsItemPage(project.id)"
            >
              <div :style="columns.name.style">
                <div class="table__td-label cursor-pointer">
                  {{ project.name }}
                </div>
              </div>

              <div :style="columns.client.style">
                <template v-if="project.client">
                  {{ project.client.name }}
                </template>
              </div>

              <div :style="columns.managers.style">
                <div v-if="project.managers" class="avatar-group">
                  <BmAvatar
                    v-for="(manager, i) in project.managers.slice(0, 3)"
                    :key="'pm' + manager.id"
                    v-tooltip="manager.name"
                    :src="manager.avatar"
                    :style="avatarStyles(i)"
                    bordered
                    size="lg"
                    @click.stop="setFilterUser(manager)"
                  />

                  <BmAvatar
                    v-if="project.managers.length > 3"
                    v-tooltip="tooltipOptions(project.managers.slice(3))"
                    :style="avatarStyles(3)"
                    bg-color="ach-90"
                    bordered
                    color="grey-dark"
                    size="lg"
                  >
                    +{{ project.managers.length - 3 }}
                  </BmAvatar>
                </div>
              </div>

              <div :style="columns.budget.style">
                {{ numberWithSpaces(project.expenses) }} /
                {{ numberWithSpaces(project.budget) }}
              </div>

              <div class="text-right" style="width: 30px">
                <BmButton
                  bg-color="white"
                  class="table__icon-more"
                  dropdown-left
                  size="xs"
                >
                  <BmIconMore />
                  <template #dropdown>
                    <div class="drop-menu">
                      <div
                        class="drop-menu__item"
                        @click="initRemoveProject(project.id)"
                      >
                        <div class="flex items-center text-red">
                          <BmIconTrash class="mr-2" />
                          Удалить
                        </div>
                      </div>
                    </div>
                  </template>
                </BmButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import {mapActions, mapState, mapMutations} from 'vuex'
import OverlayScrollbars from '@src/components/overlay-scrollbars/OverlayScrollbars'
import SkeletonTable from './ProjectsSkeletonTable'
import projectsListColumns from '../_config/projectsPageColumns'
import tooltipOptions from '../_config/managerTooltip'
import {numberWithSpaces} from '@/utils/helpers'

export default {
  name: 'ProjectsTable',

  components: {
    SkeletonTable,
    OverlayScrollbars,
  },

  props: {
    tabs: [],
  },

  data() {
    return {
      users: [],
      managers: [],

      columns: projectsListColumns,

      sortColumn: null,
      sortDirection: null,
    }
  },

  methods: {
    ...mapMutations({
      setFilterUser: 'projects/setFilterUser',
    }),
    ...mapActions({
      fetchProjects: 'projects/fetchAll',
      allUsers: 'users/fetchAll',
    }),

    toProjectsItemPage(id) {
      this.$router.push({name: 'ProjectPage', params: {id: id}})
    },

    avatarStyles(idx) {
      return {
        transform: 'translateX(-' + idx * 40 + '%)',
      }
    },

    initRemoveProject(projectId) {
      this.$emit('remove:project', projectId)
    },

    setSort(column) {
      if (this.sortColumn === column) {
        this.sortDirection =
          this.sortDirection === null
            ? 'asc'
            : this.sortDirection === 'asc'
            ? 'desc'
            : null
      } else {
        this.sortColumn = column
        this.sortDirection = 'asc'
      }
    },

    thLabelClasses(column) {
      return [
        column.sortable ? 'table__th-sortable' : '',
        column.sortLeft ? 'table__th-sortable_left' : '',
        column.name === this.sortColumn
          ? 'table__th-sortable_' + this.sortDirection
          : '',
      ]
    },

    tooltipOptions,
    numberWithSpaces,
  },

  computed: {
    ...mapState({
      projects: (state) => state.projects.allProjects,
      currentTabKey: (state) => state.projects.currentTabKey,
      filterUser: (state) => state.projects.filterUser,
    }),

    filteredProjects() {
      let projects = this.projects

      if (this.filterUser) {
        projects = this.projects.filter((project) =>
          project.managers.find((m) => m.id === this.filterUser.id)
        )
      }

      if (this.sortDirection) {
        projects = this.lodash.orderBy(
          projects,
          (project) => eval('project.' + this.sortColumn),
          this.sortDirection
        )
      }

      return projects
    },
  },

  async created() {
    await this.fetchProjects()
    this.users = await this.allUsers()
    this.managers = this.users.filter(
      (user) =>
        user.position &&
        process.env.VUE_APP_MANAGER_POSITION_IDS.split(',').includes(
          user.position.id.toString()
        )
    )
  },
}
</script>
