<template>
  <div class="table">
    <div class="table__tbody">
      <div
        v-for="n in currentRowsCount"
        :key="n"
        class="table__tr table__tr_body"
      >
        <div class="table__td">
          <div class="table__td-content">
            <div :style="cols.name.style">
              <div class="table__td-label">
                <BmSkeleton
                  :type="columns.name.skeleton.type"
                  :width="getSize(columns.name.skeleton.width) + 'px'"
                  animation="blink"
                />
              </div>
            </div>
            <div :style="cols.client.style">
              <BmSkeleton
                :type="columns.client.skeleton.type"
                :width="getSize(columns.client.skeleton.width) + 'px'"
                animation="blink"
              />
            </div>
            <div :style="cols.managers.style">
              <BmSkeleton
                :height="getSize(columns.managers.skeleton.height) + 'px'"
                :type="columns.managers.skeleton.type"
                :width="getSize(columns.managers.skeleton.width) + 'px'"
                animation="blink"
              />
            </div>
            <div
              :style="cols.budget.style"
              class="flex items-center justify-end"
            >
              <BmSkeleton
                :type="columns.budget.skeleton.type"
                :width="getSize(columns.budget.skeleton.width) + 'px'"
                animation="blink"
              />
            </div>
            <div style="width: 30px"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {randomInteger} from '@src/utils/helpers'
import projectsPageColumns from '../_config/projectsPageColumns'

export default {
  name: 'ProjectsSkeletonTable',

  props: {
    count: {
      type: Number,
    },
  },

  data() {
    return {
      currentRowsCount: this.count || 5,

      cols: projectsPageColumns,

      columns: {
        name: {skeleton: {type: 'rect', width: [110, 270]}},
        client: {skeleton: {type: 'rect', width: [80, 160]}},
        managers: {skeleton: {type: 'circle', width: 32, height: 32}},
        budget: {skeleton: {type: 'rect', width: [40, 80]}},
      },
    }
  },

  methods: {
    getSize(value) {
      if (Array.isArray(value)) {
        return randomInteger(...value)
      }
      return value
    },
  },
}
</script>
