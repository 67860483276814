<template>
  <div>
    <div class="content mt-8">
      <projects-table
        v-if="currentTabKey === 'list'"
        :tabs="tabs"
        @remove:project="onRemoveProject"
      />

      <workload-table v-if="currentTabKey === 'workload'" :tabs="tabs" />
    </div>

    <!--    <BmSticky :offset="[32, 24]" position="bottom-right">
      <BmButton label="Создать проект" rounded size="md">
        <template #icon>
          <BmIconAddBtn class="mr-2" />
        </template>
      </BmButton>
    </BmSticky>-->

    <BmModal :open.sync="confirmRemoveProjectDialog" class="modal_xs">
      <template #header>
        <div class="title-h2">
          {{ removeProjectDialog.title }}
        </div>
      </template>

      <div class="my-6 pr-16">
        {{ removeProjectDialog.text }}
      </div>

      <div class="flex items-center justify-end">
        <BmButton
          bg-color="white"
          class="mr-2"
          color="grey-dark"
          flat
          label="Отменить"
          size="md"
          @click.native="modalReject"
        />

        <BmButton
          bg-color="red"
          label="Удалить"
          rounded
          size="md"
          @click="modalResolve"
        />
      </div>
    </BmModal>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import ProjectsTable from './_components/ProjectsTable'
import WorkloadTable from './_components/WorkloadTable'

export default {
  name: 'ProjectsPage',
  components: {
    ProjectsTable,
    WorkloadTable,
  },

  data() {
    return {
      tabs: [
        {
          label: 'Список',
          key: 'list',
        },
        {
          label: 'Загрузка',
          key: 'workload',
        },
      ],

      confirmRemoveProjectDialog: false,
      removeProjectCandidate: null,
      removeProjectDialog: {
        title: 'Удалить проект?',
        text: 'Вы уверены что хотите удалить этот проект?',
      },

      modalResolve: null,
      modalReject: null,
    }
  },

  methods: {
    confirmModal() {
      return new Promise((resolve, reject) => {
        this.confirmRemoveProjectDialog = true
        this.modalResolve = () => {
          resolve()
        }
        this.modalReject = () => {
          reject()
        }
      })
    },

    async onRemoveProject(projectId) {
      try {
        await this.confirmModal()
        await this.$store.dispatch('projects/removeProject', projectId)
      } catch (e) {
      } finally {
        this.confirmRemoveProjectDialog = false
      }
    },
  },

  computed: mapState({
    currentTabKey: (state) => state.projects.currentTabKey,
  }),
}
</script>
